import(/* webpackMode: "eager" */ "/vercel/path0/components/Other/CheckInAndOut.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Other/MotionComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Exterier/entrance.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Exterier/front-main.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Exterier/front.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Exterier/playground.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Home/1-home.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Home/2-home.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Home/3-home.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Home/4-home.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Home/card.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Kitchen/bottom_kitchen.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Kitchen/upper_kitchen.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Rooms/1S.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Rooms/1T.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Rooms/2S.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Rooms/2T.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Rooms/3T-2.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Rooms/3T.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Rooms/4S.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Rooms/4T.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Rooms/6T-2.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Rooms/6T-3.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Rooms/6T.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Services/komenda.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Services/meeting_room.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Services/ski.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Surrondings/adrspach.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Surrondings/dolce.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Surrondings/krkonose.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Surrondings/kuks.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Surrondings/trutnov_centrum.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/Surrondings/zoo.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/WC/20240619_153549.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/WC/20240619_154318.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/WC/20240619_154331.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/WC/20240619_161844.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/WC/20240619_162044.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/gallery/WC/20240619_162055.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/asphalt_circuit.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/athletic_stadium.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/beach.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/book.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/bowl.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/breakfast.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/bus.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/bus2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/call.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/call2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/climbing_wall.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/cross_ski.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/czech_flag.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/datetime.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/downhill.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/downhill2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/facebook.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/footer_logo.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/fridge.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/group.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/gym.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/gymnastic_hall.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/icon_image.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/instagram.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/laundry.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/location.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/location2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/menu.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/message.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/message2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/multipurpose_court.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/multipurpose_gym.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/navbar_logo.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/parking.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/playground.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/profile.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/room.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/shower.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/toilet.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/tv.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/tv2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/usa_flag.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/wheelchair.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/vectors/wifi.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/asphalt_circuit.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/atheletics_stadium.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/bathtub.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/beds_from_200.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/book_now.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/bus.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/call-calling.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/climbing_hall.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/cup.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/facebook.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/fitness_gym.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/footer_logo_text.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/footer_logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/footer_logo2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/free_parking.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/free_wifi.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/fridge.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/garden_with_playground.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/group_discounts.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/gymnastics_hall.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/image.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/instagram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/laundry.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/location.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/main_logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/multipurpose_court.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/multipurpose_gym.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/navbar_logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/ski_park.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/ski_trails.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/sms.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/sports_hall.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/supper.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/toilet.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/transport.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/tv_rounded.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/tv_square.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/tv.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/user.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/user2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/volleyball_court.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/vectors/svg/wheelchair_accessible.svg");
